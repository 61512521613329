/* eslint-disable max-len */
import React from 'react';
import { Col, Container, ResponsiveEmbed, Row } from 'react-bootstrap';
import { Footer, Layout, Navbar, PageHeader, Sponsor } from '@components';
import { videoFallback } from '@variables';
import { Link } from 'gatsby';
import '@scss/help-page.scss';

const HelpPage = () => <Layout className="help-page">
  <Navbar />
  <PageHeader
    title="Help and Agenda"
    paddingBottom="74px"
    titleTransform="uppercase"
  />

  <Container style={{ marginBottom: `1rem`, marginTop: `3rem` }}>
    <Row>
      <ResponsiveEmbed aspectRatio="16by9">
        <iframe
          title="Walkthrough"
          src={
            videoFallback ?
              `` :
              `https://player.vimeo.com/video/698241288?h=631cb769ac`
          }
          className="embed-responsive-item"
          allow="autoplay; fullscreen"
          allowFullScreen />
      </ResponsiveEmbed>
    </Row>

    <Row>
      <Col className="itinerary">
        <h2>Itinerary</h2>

        <p><strong>8:50AM</strong> – Navigate to our <Link to="/" style={{ color: `red` }}>Home Page</Link></p>

        <p>
          <strong>9:00AM</strong> – Tune in for the live Opening Ceremony and hear from:
          <ul>
            <li>
              The emcee: Lily Botsyoe
            </li>
            <li>
              Dean Larry Johnson
            </li>
            <li>
              Rebekah Michael
            </li>
            <li>
              Provost Valerio Ferme
            </li>
            <li>
              David Adams
            </li>
            <li>
              Keynote Speaker, James Osborn, VP of Engineering for Merchant Acquiring, Protect, and Checkout Solutions,
              FIS
            </li>
          </ul>
        </p>

        <p>
          <strong>10:00AM-1:00PM</strong> – Virtual IT Expo is Open
          <ul>
            <li>
              Main stage feed will broadcast important information about the school,
              upcoming events, animations, and interviews
            </li>
            <li>
              Explore the Senior Design Projects by navigating to the Senior Design page. You can chat with the Senior Design groups and ask them questions.
            </li>
            <li>
              Explore the High School Projects by navigating to the High School page. You can chat with the High School groups and ask them questions.
            </li>
            <li>
              Explore the Research Presentations and Lightning Talks by navigating to the Research Symposium page. You can chat with the Research Symposium participants and ask them questions.
            </li>
            <li>
              Explore the Sponsors by navigating to the Our Sponsors page. You can chat with the Sponsors on their page and ask them questions. Engage in a discussion about their company, their recruitment efforts, and how IT works in their company.
            </li>
            <li>
              Explore the SoIT page and ask questions on the various navigation pages.
            </li>
            <li>
              Tune in to the Live Stream of our judging (Senior Design, Research Symposium & High School competition) by navigating to the “Live” tab and selecting what you would like to watch.
            </li>
            <li>
              Join a Live Zoom session for SoIT presentations to learn more about SoIT. The schedule for these presentations are:
            </li>
            <ul>
              <li>
                Early IT Program	10:05-10:35AM
              </li>
              <li>
                Co-Op	10:40-11:10AM
              </li>
              <li>
                SoIT Programs	11:15-11:55AM
              </li>
            </ul>
            <li>
              Join a Live Zoom session for SoIT presentations to learn more about SoIT. The schedule for these presentations are:
            </li>
            <ul>
              <li>
                Western & Southern	10:00-10:15AM
              </li>
              <li>
                Macy's	10:15-11:30AM
              </li>
              <li>
                Cincinnati Insurance Companies	10:30-10:45AM
              </li>
              <li>
                Brooksource 10:45-11:00AM
              </li>
              <li>
                Western & Southern 11:00-11:15AM
              </li>
              <li>
                Marathon 11:15-11:30AM
              </li>
            </ul>
          </ul>
        </p>

        <p className="ml-4">
          <p>
            <strong>10-1AM</strong> – Navigate to Sponsors pages and engage in the chat function:
            <ul>
              <li><Link to="/sponsors/28" style={{ color: `red` }}>FIS</Link></li>
              <li><Link to="/sponsors/26" style={{ color: `red` }}>Western & Southern</Link></li>
              <li><Link to="/sponsors/32" style={{ color: `red` }}>Brooksource</Link></li>
              <li><Link to="/sponsors/34" style={{ color: `red` }}>Cincinnati Insurance Companies</Link></li>
              <li><Link to="/sponsors/36" style={{ color: `red` }}>Macy's</Link></li>
              <li><Link to="/sponsors/37" style={{ color: `red` }}>Marathon</Link></li>
            </ul>
          </p>
        </p>

        <p><strong>12:55PM</strong> – Navigate to our <Link to="/" style={{ color: `red` }}>Home Page</Link></p>

        <p>
          <strong>1-1:30PM</strong> – Tune in for the Closing Ceremony and hear from
          <ul>
            <li>
              Dr. Hazem Said, Ph.D – Director and Professor, UC School of Information Technology
            </li>
          </ul>
        </p>

        <p>
          <strong>1:35PM</strong> – Follow our Social Media Pages
          (<Link to="https://twitter.com/UC_SOIT" style={{ color: `red` }}>Twitter</Link>,&nbsp;
          <Link to="https://www.instagram.com/uc_soit/" style={{ color: `red` }}>Instagram</Link>,&nbsp;
          <Link to="https://www.facebook.com/ucsoit/" style={{ color: `red` }}>Facebook</Link>,&nbsp;
          <Link to="https://www.linkedin.com/company/uc-school-of-information-technology/" style={{ color: `red` }}>
            LinkedIn
          </Link>) as we announce the winners from all of our participants!
        </p>
      </Col>
    </Row>
  </Container>

  <Sponsor />
  <Footer />

</Layout>;

export default HelpPage;
